import { RootState } from "../redux/store";

export interface SuggestedPrompt {
    title: string;
    description: string;
    prompt: string;
}

class SuggestedPromptsService {
    static defaultPrompts: SuggestedPrompt[] = [
        { title: 'Job Matching', description: 'Can you provide a list of jobs that are a great match for me?', prompt: 'Given my current career objectives:\n[DESIRED_INFO]\n return the top jobs matching them. Fetch my job search criteriaif needed. When returning job positions, force-rank the jobs and provide job title, a link to the job, company name, location, fit score, and key skills, and output this information in a table format. Make the fit score the first column, and make sure the fit score is out of 100.Use the headers "Job Title", "Fit Score", "Company", "Location", and "Skills".' },
        { title: 'Resume Optimization', description: 'Can you reconfgure my resume to be more compelling?', prompt: "[PROMPT2]Given my resume:\n\n[RESUME_TEXT]\n\nRe-write and reformat my resume into a professional format suitable for my job discipline that leverages more action words and is generally more compelling to the reader. Preserve all the key details and make sure not to fabricate any new experience that does want already exist on my resume. After you've done that I'd like to customize it for specific jobs." },
        { title: 'Cover Letter', description: 'Can you help me create a compelling cover letter?', prompt: "[PROMPT3]Given my resume:\n\n[RESUME_TEXT]\n\nCreate a cover letter that is compelling and simple. Don't mention a specific job or position, make it generic" },
        { title: 'Upskilling', description: 'What skills can I develop to improve my career prospects?', prompt: '[PROMPT4]Given my resume:\n\n[RESUME_TEXT]\n\n Based on my resume, what hard skills and soft skills would you suggest I learn in order to improve my marketability and career prospects? For each area please suggest specific resources for up-skilling in those areas along with links to the specific courses you mention, their price range and approximate time required ideally in a table format. Please also add in a skill gap score and a skill value score for each and explain what those mean.Fetch the courses using a function call.' },
        { title: 'Interview Prep', description: 'What interview questions should I be prepared for?', prompt: '[PROMPT5]Given my resume:\n\n[RESUME_TEXT]\n\n What common interview questions should I prepare for these job roles? Please create lists with technical questions, experience-based and soft skills-based.' },
        { title: 'Mock Interview', description: "Can we do a mock interview based on my job matches?", prompt: "[PROMPT6]Given my resume:\n\n[RESUME_TEXT]\n\n What are some interview questions I should prepare to answer given the roles I'm persuing?" },
    ]
    // Could be a network call in the future to get customized prompts for user
    getSuggestedPrompts = (): SuggestedPrompt[] => {
        // Return the defaults for now
        return SuggestedPromptsService.defaultPrompts;
    }

    getFancySuggestedPrompts = (state: RootState): SuggestedPrompt[] => {
        return SuggestedPromptsService.defaultPrompts.map((prompt, index) => {
            if (index === 0) {
                const funnyPrompt: SuggestedPrompt = { title: 'Job Matching', description: 'Can you provide a list of jobs that are a great match for me?', prompt: `[PROMPT1]${state.promptState.jobMatchPrompt}` };
                return funnyPrompt;
            }
            return prompt;
        });
    }

    static jobPrompts: SuggestedPrompt[] = [
        { title: '', description: 'Compare my resume to the requirements for the job', prompt: '[JOB1]Given my resume:\n\n[RESUME_TEXT]\n\nPlease provide a detailed fit analysis of my resume in comparison to the given job description. Please include a list of pros, and a list of cons. Please also highlight any key skills I am missing and recommend courses for upskilling in those areas based on your knowledge base.' },
        { title: '', description: 'Customize my resume for the job', prompt: "[JOB2]Given my resume:\n[RESUME_TEXT]\nCustomize my resume for the given job. Please produce a full version of my resume with all of my career history/experience, education history, etc. Don't fabricate any net new experience without first asking me if i have that experience. Utilize more action words and make the content generally more compelling. Re-order my experience to be relevant to how it is positioned in the job description. Ensure it is professionally formatted (bold headings, italics, etc.) and well organized. The goal is to increase the likelihood of getting an interview for the role. Don't use any heading tags, just bold the text for headings." },
        { title: '', description: 'Create a cover letter for the job', prompt: "[JOB3]Given my resume:\n\n[RESUME_TEXT]\n\nPlease craft a compelling cover letter for the job description included here. Ensure you have included some relevant information about the company that is of interest to me. Make sure it clearly and succinctly presents my most relevant experience and inclines the reader to review my resume and ultimately helps me to secure an interview." },
        { title: 'interview', description: 'Start a mock interview for the job', prompt: "[JOB4]ROLE: Act as an expert domain resource that would be interviewing me for my job-related skills, soft skills as well as my major accomplishments.INTERVIEW OVERVIEW: Please conduct an adaptive interview (ask questions in sequence, not in parallel…ask one question only, await the answer to that question and then ask the next).  To be clear, do not ask multiple questions at the same time. Prior to commencing the interview please ask how many questions I would like the interview to be and then jump into the interview.INTERVIEW ASSESSMENT: At the end of the interview (after all questions have been asked and answered, not after each question) provide feedback on my answer to each question along with a rating for each, summary analysis, and suggested improvements. To be clear, do this after the interview is done and not after each question." },
        { title: '', description: 'What skills do I need to develop to be a stronger applicant for the job', prompt: "[JOB5]Given my resume:\n\n[RESUME_TEXT]\n\nBased on a detailed analysis of my resume in comparison to the job description please determine key gaps in my skills/experience and the job requirements and based on that analysis recommend courses from your knowledge base to upskill in those areas." },
        { title: 'expired', description: 'Find similar jobs', prompt: '[JOB6]Find jobs similar to this one.' },
    ];
    getJobPrompts = (): SuggestedPrompt[] => {
        return SuggestedPromptsService.jobPrompts;
    }

}

const suggestedPromptsService = new SuggestedPromptsService();

export default suggestedPromptsService;
