import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import { TFunction } from 'i18next';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AppLogoRounded from '../../assets/images/app_logo_rounded';
import { sendMessageAction } from '../../redux/actions/ChatActions';
import { StartInterviewAction } from '../../redux/actions/InterviewActions';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { focusText, setCurrentChatAction } from '../../redux/reducers/appReducer';
import { AppDispatch } from '../../redux/store';
import suggestedPromptsService, { SuggestedPrompt } from '../../services/suggestedPromptsService';
import ChatBar from '../components/ChatBar';
import PromptCard from '../components/PromptCard';
import ChatPage from './ChatPage';
import LoadingChatPage from './LoadingChatPage';
import RightPaneContainer from './RightPaneContainer';


const Body = styled('div')((props: { modalOpen: boolean, isMobile: boolean }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100vw',
    minHeight: props.isMobile ? '100%' : '100vh',
    overflowY: props.modalOpen ? 'hidden' : 'auto',
    flex: 1,
}));

const PromptGrid = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
}));

interface WelcomeViewProps {
    t: TFunction<"translation", undefined>;
}
const WelcomeView = (props: WelcomeViewProps) => {
    const { t } = props;
    const dispatch = useAppDispatch();
    const currentUser = useAppSelector((s) => s.userState.currentUser);
    const state = useAppSelector((s) => s);

    useEffect(() => {
        dispatch(focusText());
    }, []);

    const injectResumeIntoPrompt = (prompt: string) => {
        const resumeText = currentUser?.resume?.textResume;
        if (resumeText) {
            const newPrompt = prompt.replace("[RESUME_TEXT]", resumeText);
            dispatch(sendMessageAction(newPrompt));
        } else {
            const promptNumberRegex = /\[PROMPT\d+\]/;
            const promptNumberMatch = prompt.match(promptNumberRegex);
            if (promptNumberMatch) {
                const promptNumber = promptNumberMatch[0];
                dispatch(sendMessageAction(`${promptNumber}This user hasn't uplaoded a resume yet. Please tell them "I see you haven't uploaded a resume yet. Please upload your resume in your profile settings so I can help you with this."`));
            } else {
                console.error(`Prompt didn't contain a prompt number like '[PROMPT2]'`, prompt)
            }
            return;
        }
    }

    const onPromptCardPressed = (prompt: string) => {
        dispatch(setCurrentChatAction());
        if (prompt.startsWith("[PROMPT6]")) {
            dispatch(StartInterviewAction());
            return;
        } else if (prompt.includes("[RESUME_TEXT]")) {
            injectResumeIntoPrompt(prompt);
            return;
        }
        dispatch(sendMessageAction(prompt));
    }
    return (
        <Box sx={{ maxWidth: '1120px', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'safe center', margin: 'auto' }}>
            <Box sx={{ marginTop: '28px' }}>
                <AppLogoRounded />
                <Typography variant='h2' sx={{ marginTop: '33px', marginBottom: '55px' }}>
                    {t('helpMessage')}
                </Typography>
            </Box>
            <PromptGrid>
                {suggestedPromptsService.getFancySuggestedPrompts(state).map((suggestedPrompt: SuggestedPrompt) => {
                    return <PromptCard key={suggestedPrompt.title} onClick={onPromptCardPressed} title={suggestedPrompt.title} description={suggestedPrompt.description} prompt={suggestedPrompt.prompt} />;
                })}
            </PromptGrid>
            <div style={{ minHeight: '130px', maxHeight: '130px' }} />
        </Box>
    );
}

const messageSubmitted = (message: string, dispatch: AppDispatch) => {
    if (message.trim().length === 0) return;
    dispatch(sendMessageAction(message));
}

const MainChatPage = () => {
    const { t } = useTranslation();
    const activeChat = useAppSelector((s) => s.appState.activeChat);
    const fetchingChat = useAppSelector((s) => s.appState.fetchingChat);
    const focusedJob = useAppSelector((s) => s.appState.focusedJob);
    const focusedResource = useAppSelector((s) => s.resourceState.focusedResource);
    const focusedResume = useAppSelector((s) => s.documentState.focusedResume);
    const focusedInterview = useAppSelector((s) => s.interviewState.focusedInterview);
    const isMobile = useAppSelector((s) => s.appState.isMobile);
    const dispatch = useAppDispatch();


    const attachPressed = () => {
        console.log('Attach!');
        // TODO
    }

    return (
        <Body modalOpen={!!focusedJob || !!focusedResource} isMobile={isMobile}>
            {!!fetchingChat ? <LoadingChatPage /> : (!!activeChat ? <ChatPage chat={activeChat} /> : <WelcomeView t={t} />)}
            <ChatBar
                onAttachPressed={attachPressed}
                onSubmit={(message: string) => messageSubmitted(message, dispatch)}
            />
            {focusedJob && <RightPaneContainer job={focusedJob} />}
            {focusedResume && <RightPaneContainer resume={focusedResume} />}
            {focusedInterview && <RightPaneContainer interview={focusedInterview} />}
            {!focusedInterview && focusedResource && <RightPaneContainer resource={focusedResource} />}
        </Body>
    );
};

export default MainChatPage
