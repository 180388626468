import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface DocumentsState {
    focusedResume?: string;
    focusedResumeTitle?: string;
}

const initialState: DocumentsState = {
};

export interface FocusedResumeInfo {
    content: string;
    title: string;
}

export const documentsSlice = createSlice({
    name: 'documents',
    initialState,
    reducers: {
        setFocusedResume: (state, action: PayloadAction<FocusedResumeInfo>) => {
            state.focusedResume = action.payload.content;
            state.focusedResumeTitle = action.payload.title;
        },
        clearFocusedResume: (state) => {
            state.focusedResume = undefined;
            state.focusedResumeTitle = undefined;
        }
    },
})

export const {
    setFocusedResume,
    clearFocusedResume,
} = documentsSlice.actions

export default documentsSlice.reducer
