import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { ReactNode } from 'react';
import InterviewContentPage from '../chat/pages/InterviewContentPage';
import JobDetailsContentPage from '../chat/pages/JobDetailsContentPage';
import MainChatPage from '../chat/pages/MainChatPage';
import ResourceDetailsContentPage from '../chat/pages/ResourceDetailsContentPage';
import ResumeContentPage from '../chat/pages/ResumeContentPage';
import ProfileNavigationPage from '../profile/ProfileNavigationPage';
import { useAppSelector } from '../redux/hooks';
import { sharpTransition } from '../theme/AppTheme';
import NavigationConstants from './NavigationConstants';
import { MainTab, MainTabType, profileTabIndex } from './classes/MainTab';
import AppDrawer from './components/AppDrawer';

const MainContentView = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    transition: `margin-left 0.2s ${sharpTransition}, width 0.2s ${sharpTransition}`,
}));

const DesktopNavigation = () => {
    const selectedTab = useAppSelector(store => store.appState.selectedTab);
    const drawerOpen = useAppSelector(store => store.appState.drawerIsOpen);
    const sidebarOpen = useAppSelector(store => store.appState.sidebarIsOpen);
    const selectedJob = useAppSelector(store => store.savedJobsState.selectedJob);
    const selectedResource = useAppSelector(store => store.resourceState.selectedSavedResource);
    const selectedInterview = useAppSelector(store => store.interviewState.selectedInterview);
    const selectedSavedResume = useAppSelector(store => store.savedResumesState.selectedResume);

    const margin = `${(drawerOpen ? NavigationConstants.drawerWidthOpen : NavigationConstants.drawerWidthClosed) + (sidebarOpen ? NavigationConstants.sidebarWidth : 0)}px`;

    const activeView = (): ReactNode => {
        if (selectedTab === profileTabIndex) {
            return <ProfileNavigationPage />;
        } else if (selectedTab === MainTabType.savedJobs && !!selectedJob) {
            return <JobDetailsContentPage animated={false} job={selectedJob} />;
        } else if (selectedTab === MainTabType.savedResources && !!selectedResource) {
            return <ResourceDetailsContentPage animated={false} resource={selectedResource} />;
        } else if (selectedTab === MainTabType.interviews && !!selectedInterview) {
            return <InterviewContentPage animated={false} interview={selectedInterview} />;
        } else if (selectedTab === MainTabType.savedResumes && !!selectedSavedResume) {
            return <ResumeContentPage animated={false} savedResume={selectedSavedResume} messageIndex={0} />;
        }
        return (selectedTab < 0 ? <MainChatPage /> : new MainTab(selectedTab as MainTabType).view);
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <CssBaseline />
            <AppDrawer isMobile={false} />
            <MainContentView sx={{
                height: '100vh',
                width: `calc(100% - ${margin})`,
                marginLeft: `${(drawerOpen ? NavigationConstants.drawerWidthOpen : NavigationConstants.drawerWidthClosed) + (sidebarOpen ? NavigationConstants.sidebarWidth : 0)}px`,
            }}>
                {activeView()}
            </MainContentView>

        </Box>
    );
}

export default DesktopNavigation;
