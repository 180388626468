import styled from "@emotion/styled";
import { Modal, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import DownloadIcon from "../../assets/icons/download_icon";
import EyeIcon from "../../assets/icons/eye_icon";
import InterviewIcon from "../../assets/icons/interview_icon";
import SaveIconSelected from "../../assets/icons/save_icon_selected";
import SaveIconUnselected from "../../assets/icons/save_icon_unselected";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { addSavedInterview, removeSavedInterview, setFocusedInterview } from "../../redux/reducers/interviewReducer";
import InterviewService from "../../services/interviewService";
import ClickableOpacityDiv from "../../shared/components/ClickableOpacityDiv";
import { AppColors } from "../../theme/AppTheme";
import DownloadFileModal from "./DownloadFileModal";

const StyledButtonRow = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '24px',
    margin: '12px 20px',
    maxWidth: '340px',

}));

const StyledButton = styled('div')(() => ({
    border: `1px solid ${AppColors.grey.light}`,
    minHeight: '65px',
    width: '100%',
    maxWidth: '370px',
    borderRadius: '8px',
    marginBottom: '15px'
}));

const StyledColumn = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    margin: '12px 20px',
}));


const SaveInterviewAccessory = () => {
    const activeChat = useAppSelector(state => state.appState.activeChat);
    const interview = activeChat?.interview;
    const isMobile = useAppSelector(state => state.appState.isMobile);
    const [downloadOpen, setDownloadOpen] = useState(false);
    const savedInterviews = useAppSelector(state => state.interviewState.interviewsArray);
    const [isSaved, setIsSaved] = useState(savedInterviews.some(i => i.id === interview?.id));
    const interviewToggle = useAppSelector(state => state.interviewState.interviewToggle);

    const dispatch = useAppDispatch();


    useEffect(() => {
        setIsSaved(savedInterviews.some(i => i.id === interview?.id));
    }, [interviewToggle]);

    const onBookmarkToggled = async () => {
        if (!interview) return;
        if (isSaved) {
            await InterviewService.removeSavedInterview(interview);
            dispatch(removeSavedInterview(interview));
        } else {
            await InterviewService.saveInterview(interview);
            dispatch(addSavedInterview(interview));
        }
        setIsSaved(!isSaved);
    }

    const onDownload = () => {
        setDownloadOpen(true);
    }

    const onView = () => {
        if (!interview) return;
        dispatch(setFocusedInterview(interview));
    }

    return (
        <div>
            <Typography variant='body2' style={{ marginBottom: '32px' }}>
                Your Interview Results:
            </Typography>
            <StyledButton >
                <ClickableOpacityDiv
                    onClick={onView}
                    gradientBorder={!isMobile} style={{
                        borderRadius: '8px',
                        overflow: 'hidden',
                        boxShadow: '0px 0px 3px 1px rgba(248, 248, 248, 1)',
                        display: 'flex',
                        height: '100%',
                        flexDirection: 'row',
                        alignItems: 'stretch',
                    }}>
                    <span style={{ minWidth: '73px', backgroundColor: AppColors.black, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <InterviewIcon color={AppColors.white} />
                    </span>
                    <StyledColumn>
                        <Typography variant='body1' style={{ marginBottom: '3px', fontSize: '12px', lineHeight: '19px' }}>
                            {activeChat?.sessionTitle ?? ''}
                        </Typography>
                        <Typography variant='body1' style={{ marginBottom: '3px', fontSize: '12px', fontWeight: 500, color: AppColors.grey.darkish, lineHeight: '19px' }}>
                            Questions: <span style={{ marginLeft: '10px' }}>{interview?.totalQuestions ?? 0}</span>
                            <span style={{ marginLeft: '30px' }}>
                                Score: <span style={{ marginLeft: '10px' }}>
                                    {interview?.rating ?? 0}/{(interview?.questions?.length ?? 0) * 5}
                                </span>
                            </span>
                        </Typography>
                    </StyledColumn>
                </ClickableOpacityDiv>
            </StyledButton>
            <StyledButtonRow>
                <ClickableOpacityDiv onClick={onDownload} style={{ gap: '10px' }}>
                    <DownloadIcon />
                    <Typography variant='caption' style={{ color: AppColors.grey.dark, fontSize: '12px', fontWeight: 600 }}>
                        Download
                    </Typography>
                </ClickableOpacityDiv>
                <ClickableOpacityDiv onClick={onBookmarkToggled} style={{ gap: '10px' }}>
                    {isSaved ? <SaveIconSelected color={AppColors.grey.dark} /> : <SaveIconUnselected color={AppColors.grey.dark} />}
                    <Typography variant='caption' style={{ color: AppColors.grey.dark, fontSize: '12px', fontWeight: 600 }}>
                        {isSaved ? 'Saved' : 'Save'}
                    </Typography>
                </ClickableOpacityDiv>
                <ClickableOpacityDiv onClick={onView} style={{ gap: '10px' }}>
                    <EyeIcon />
                    <Typography variant='caption' style={{ color: AppColors.grey.dark, fontSize: '12px', fontWeight: 600 }}>
                        View
                    </Typography>
                </ClickableOpacityDiv>
            </StyledButtonRow>
            <Modal
                open={downloadOpen}
                onClose={() => setDownloadOpen(false)}
                sx={{ zIndex: 999999, }}
            >
                {/* TODO: Use proper interview summary function with all formatting */}
                <DownloadFileModal title={activeChat?.sessionTitle} content={interview?.finalSummary ?? ''} onDownloaded={() => setDownloadOpen(false)} />
            </Modal>
        </div>
    )
}

export default SaveInterviewAccessory