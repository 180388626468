import {
    $convertFromMarkdownString,
    $convertToMarkdownString,
    TRANSFORMERS
} from '@lexical/markdown';

import { Typography } from '@mui/material';

import {
    AlignDropdown,
    BoldButton,
    CodeFormatButton,
    Divider,
    Editor,
    EditorComposer,
    FontFamilyDropdown,
    FontSizeDropdown,
    ItalicButton,
    TextFormatDropdown,
    ToolbarPlugin,
    UnderlineButton
} from 'verbum';

interface LexicalEditorProps {
    initialValue?: string;
    newContent: (content: string) => void;
}

const LexicalEditor: React.FC<LexicalEditorProps> = ({ initialValue, newContent }) => {
    return (
        <div style={{ width: '100%' }}>
            <Typography variant='body1' style={{ fontWeight: 400 }}>
                <EditorComposer initialEditorState={() => $convertFromMarkdownString(initialValue ?? '', TRANSFORMERS)}>
                    <Editor hashtagsEnabled
                        onChange={(state, editor) => {
                            editor?.update(() => {
                                let markdown = $convertToMarkdownString(TRANSFORMERS);
                                markdown = markdown.replace(/(?<!\n)\n(?!\n)/g, '  \n');
                                newContent(markdown);
                            });
                        }}
                    >
                        <ToolbarPlugin defaultFontSize="20px">
                            <FontFamilyDropdown />
                            <FontSizeDropdown />
                            <Divider />
                            <BoldButton />
                            <ItalicButton />
                            <UnderlineButton />
                            <CodeFormatButton />
                            <TextFormatDropdown />
                            <Divider />
                            <AlignDropdown />
                        </ToolbarPlugin>
                    </Editor>
                </EditorComposer>
            </Typography>
        </div>
    );
}

export default LexicalEditor;