
import SavedResume from '../../mainTabs/savedResumes/model/SavedResume';
import ResumeService from '../../services/resumeService';
import { setSavedResumeBuckets, setSavedResumes } from '../reducers/savedResumeReducer';
import { AppDispatch, RootState } from '../store';


export const FetchSavedResumesAction = () => {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const resumes = await ResumeService.fetchSavedResumes();
        if (resumes) {
            const sortedResumes = resumes.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
            const today = new Date();
            let bucketKeys: string[] = ['Today', 'This Week'];
            const sortedResumesHistory = sortedResumes.reduce((buckets: { [key: string]: SavedResume[] }, resume: SavedResume) => {
                const createdDate = new Date(resume.updatedAt ?? resume.createdAt);
                const year = createdDate.getFullYear();
                const month = createdDate.toLocaleString('default', { month: 'long' });

                let bucketKey = '';
                if (createdDate.toDateString() === today.toDateString()) {
                    bucketKey = 'Today';
                } else if (createdDate >= new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay())) {
                    bucketKey = 'This Week';
                } else {
                    bucketKey = `${month} ${year}`;
                }

                if (!buckets[bucketKey]) {
                    if (!bucketKeys.includes(bucketKey)) {
                        bucketKeys.push(bucketKey);
                    }
                    buckets[bucketKey] = [];
                }
                buckets[bucketKey].push(resume);

                return buckets;
            }, {});

            for (const bucketKey in sortedResumesHistory) {
                sortedResumesHistory[bucketKey].sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
            }
            dispatch(setSavedResumeBuckets(bucketKeys));
            dispatch(setSavedResumes(sortedResumesHistory));
        }
    }
};
