import styled from '@emotion/styled';
import { Modal, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Bars } from 'react-loader-spinner';
import CoverLetterIcon from '../../assets/icons/cover_letter_icon';
import DownloadIcon from '../../assets/icons/download_icon';
import EyeIcon from '../../assets/icons/eye_icon';
import ResumeIcon from '../../assets/icons/resume_icon';
import { GenerateResumeNameAction } from '../../redux/actions/ChatActions';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setFocusedResume } from '../../redux/reducers/documentsReducer';
import ClickableOpacityDiv from '../../shared/components/ClickableOpacityDiv';
import resumeNameCache from '../../shared/utils/ResumeNameCache';
import { AppColors } from '../../theme/AppTheme';
import DownloadFileModal from './DownloadFileModal';

const StyledColumn = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    margin: '12px 20px',
}));

const GeneratingRow = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '24px',
    margin: '12px 20px',
}));

const StyledButtonRow = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '24px',
    margin: '12px 20px',
    maxWidth: '340px',

}));

const StyledButton = styled('div')(() => ({
    border: `1px solid ${AppColors.grey.light}`,
    minHeight: '65px',
    width: '100%',
    maxWidth: '370px',
    borderRadius: '8px',
    marginBottom: '15px'
}));

export enum DownloadableDocumentType {
    resume,
    coverLetter
}

interface DownloadableDocumentCellProps {
    updatedAt: string;
    type: DownloadableDocumentType;
    generating: boolean;
    content?: string;
    documentName?: string;
    messageIndex?: number;
}

const DownloadableDocumentCell = (props: DownloadableDocumentCellProps) => {
    const { updatedAt, type, content, documentName: initialName, generating, messageIndex } = props;
    const resumeNameToggle = useAppSelector(state => state.promptState.resumeNameToggle);
    const isMobile = useAppSelector(state => state.appState.isMobile);
    const [downloadOpen, setDownloadOpen] = useState(false);
    const [generatingName, setGeneratingName] = useState(false);
    const activeChat = useAppSelector(state => state.appState.activeChat);

    const dispatch = useAppDispatch();
    const [resumeName, setResumeName] = useState(initialName ?? '');

    const onDownload = () => {
        setDownloadOpen(true);
    }

    const onView = () => {
        dispatch(setFocusedResume({ content: props.content ?? '', title: initialName ?? '' }));
    }

    useEffect(() => {
        if (generatingName) return;
        const sessionId = activeChat?.sessionId;
        if (!sessionId || !content) return;
        if (!generatingName && resumeName === '') {
            setGeneratingName(true);
            dispatch(GenerateResumeNameAction(sessionId, content, messageIndex ?? 0));
        }
    }, [generating, content]);

    useEffect(() => {
        const sessionId = activeChat?.sessionId;
        if (!sessionId) return;
        const resumeName = resumeNameCache.getResumeName(sessionId, messageIndex ?? 0);
        if (!resumeName) return;
        setResumeName(resumeName);
        setGeneratingName(false);
    }, [resumeNameToggle]);

    return (
        <div>
            <Typography variant='body2' style={{ marginBottom: '32px' }}>
                Updated Files:
            </Typography>
            <StyledButton >
                <ClickableOpacityDiv
                    onClick={onView}
                    gradientBorder={!isMobile} style={{
                        borderRadius: '8px',
                        overflow: 'hidden',
                        boxShadow: '0px 0px 3px 1px rgba(248, 248, 248, 1)',
                        display: 'flex',
                        height: '100%',
                        flexDirection: 'row',
                        alignItems: 'stretch',
                    }}>
                    <span style={{ minWidth: '73px', backgroundColor: AppColors.black, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {type === DownloadableDocumentType.resume ? <ResumeIcon /> : <CoverLetterIcon />}
                    </span>
                    {generating
                        ? <GeneratingRow>
                            <Bars
                                height="30"
                                width="30"
                                color={AppColors.pink.dark}
                                ariaLabel="bars-loading"
                                visible={true}
                            />
                            <Typography variant="body1" sx={{ color: AppColors.grey.darkish }}>
                                Generating {type === DownloadableDocumentType.resume ? 'Resume' : 'Cover Letter'}
                            </Typography>
                        </GeneratingRow>
                        : <StyledColumn>
                            <Typography variant='body1' style={{ marginBottom: '3px', fontSize: '12px', lineHeight: '19px' }}>
                                {resumeName === '' ? `Loading` : resumeName}
                            </Typography>
                            <Typography variant='body1' style={{ marginBottom: '3px', fontSize: '12px', fontWeight: 500, color: AppColors.grey.darkish, lineHeight: '19px' }}>
                                Updated {updatedAt}
                            </Typography>
                        </StyledColumn>
                    }
                </ClickableOpacityDiv>
            </StyledButton>
            <StyledButtonRow>
                <ClickableOpacityDiv onClick={onDownload} style={{ gap: '10px' }}>
                    <DownloadIcon />
                    <Typography variant='caption' style={{ color: AppColors.grey.dark, fontSize: '12px', fontWeight: 600 }}>
                        Download
                    </Typography>
                </ClickableOpacityDiv>
                <ClickableOpacityDiv onClick={onView} style={{ gap: '10px' }}>
                    <EyeIcon />
                    <Typography variant='caption' style={{ color: AppColors.grey.dark, fontSize: '12px', fontWeight: 600 }}>
                        View
                    </Typography>
                </ClickableOpacityDiv>
            </StyledButtonRow>
            <Modal
                open={downloadOpen}
                onClose={() => setDownloadOpen(false)}
                sx={{ zIndex: 999999, }}
            >
                <DownloadFileModal content={content ?? ''} onDownloaded={() => setDownloadOpen(false)} />
            </Modal>
        </div>
    )
}

export default DownloadableDocumentCell