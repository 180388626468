import { ReactNode } from "react";
import { RootState } from "../../redux/store";
import InterviewType from "../../shared/enums/InterviewType";
import Chat from "../../shared/models/Chat";
import Interview, { InterviewState } from "../../shared/models/Interview";
import Message, { MessageType } from "../../shared/models/Message";
import InterviewTypeSelection from "../components/InterviewTypeSelection";
import MessageCell from "../components/MessageCell";
import NumQuestionsPills from "../components/NumQuestionsPills";
import SaveInterviewAccessory from "../components/SaveInterviewAccessory";
import SavedJobSelection from "../components/SavedJobSelection";


export const initialInterviewPrompt = (interview: Interview, thankYou?: boolean): string =>
    `${thankYou ? `Thank you!
        &nbsp;
        ` : ''}${interviewIntro(interview)}
        During the interview I will ask you a series of questions.
        Once complete, I will provide you with an analysis of your responses and suggestions for improvement.
        &nbsp;
        ${interview.totalQuestions ? `I'm going to ask you **${interview.totalQuestions} questions**.
        &nbsp;
        Let's get started!` : "How many questions would you like me to ask?"}
    `;

const interviewIntro = (interview: Interview): string => {
    switch (interview.type) {
        case InterviewType.resume:
            return `We will be conducting an interview based on positions listed on your resume.
            &nbsp;`;
        case InterviewType.job:
            return `We will be conducting an interview for this position:
            **${interview.jobTitle}**
            &nbsp;`;
        case InterviewType.specified:
            return `We will be conducting an interview for:
            **${interview.userSpecifiedRole}**
            &nbsp;`;
    }

    return '';
}

export const interviewStartMessage = (interview: Interview, state: RootState): string => {
    switch (interview.type) {
        case InterviewType.resume:
            return `I would like to do a general interview based on my current resume: ${state.userState.currentUser?.resume?.textResume}`;
        case InterviewType.job:
            return `Here's the job info.\n Job Description: ${interview.jobSummary}\nTitle: ${interview.jobTitle}\nCompany: ${interview.jobCompany}`;
        case InterviewType.specified:
            return `Please interview me for the job/role of ${interview.userSpecifiedRole}.`;
    }
    return '';
}

export const interviewTitle = (interview: Interview, state: RootState): string => {
    switch (interview.type) {
        case InterviewType.resume:
            var name = 'Your';
            const user = state.userState.currentUser;
            const firstName = user?.firstName;
            if (firstName) {
                if (name.endsWith('s')) {
                    name = `${firstName}'`;
                } else {
                    name = `${firstName}'s`;
                }
            }
            return `Interview: ${name} Resume`;
        case InterviewType.job:
            return `Interview: ${interview.jobTitle} @ ${interview.jobCompany}`;
        case InterviewType.specified:
            return `Interview: ${interview.userSpecifiedRole}`;
    }
    return '';
}

export const formatChatForInterview = (chat: Chat, interview: Interview, appendingAnswer: boolean, fromSavedJob?: boolean): Chat => {
    // Build the messages
    const messages: Message[] = [];
    // Initial Message
    messages.push({
        i: 1,
        type: MessageType.received,
        content: initialInterviewPrompt(interview, fromSavedJob),
        dt: chat.created,
    });

    // TODO: Handle case where questions were asked but not saved in interview object

    // Compare # answers to # questions
    const questions = interview.questions ?? [];
    const answers = interview.answers ?? [];
    const numAnswers = answers.length ?? 0;
    const numQuestions = questions.length ?? 0;

    let curQuestion = 0;
    if (numQuestions !== 0) {
        while (curQuestion < numAnswers + 1 && curQuestion < numQuestions) {
            // Add the question
            messages.push({
                i: curQuestion + 2,
                type: MessageType.received,
                content: `**Question ${curQuestion + 1}**: ${questions[curQuestion]}`,
                dt: chat.created,
            });

            // Add the answer
            if (curQuestion < numAnswers) {
                messages.push({
                    i: curQuestion + 3,
                    type: MessageType.sent,
                    content: answers[curQuestion],
                    dt: chat.created,
                });
            }
            curQuestion += 1;
        }
    }
    let pending = false;
    let streaming = false;
    if (numQuestions > 0 && curQuestion === numQuestions && numAnswers === numQuestions) {
        // Add final message
        if (interview.finalSummary) {
            const targetMessage = chat.messages[chat.messages.length - 1];
            messages.push({
                i: messages.length,
                type: MessageType.received,
                content: `Thank you for completing the interview!
                &nbsp;
                I have analyzed your responses and provided you with an evaluation score and feedback below. Let me know if you have any questions`,
                dt: targetMessage.dt,
            })
        } else {
            // Add a loader
            pending = true;
            streaming = true;
        }
    }
    return {
        ...chat,
        pending,
        streaming,
        messages,
        interview,
    }

}

export const formatPreInterviewChat = (chat: Chat, interview: Interview): Chat => {
    // Build the messages
    const messages: Message[] = [];
    if (interview.type === undefined) {
        messages.push({
            i: 1,
            type: MessageType.received,
            content: `What type of interview would you like?`,
            dt: chat.created,
        });
    } else {
        switch (interview.type) {
            case InterviewType.job:
                messages.push({
                    i: 1,
                    type: MessageType.received,
                    content: `What saved job would you like to do an interview for?`,
                    dt: chat.created,
                });
                break;
            case InterviewType.resume:
                return formatChatForInterview(chat, interview, false, true);
            case InterviewType.specified:
                messages.push({
                    i: 1,
                    type: MessageType.received,
                    content: `What type of job / role would you like to do an interview for?`,
                    dt: chat.created,
                });
                break;
        }
    }
    // Initial Message
    return {
        ...chat,
        messages,
        interview,
    }
}

export const messageCellForInterviewChat = (message: Message, interview: Interview): ReactNode => {
    switch (interview.type) {
        case InterviewType.job:
        case InterviewType.resume:
        case InterviewType.specified:
            return interviewMessageCell(message, interview);

        default:
            return (<>
                <MessageCell
                    key={0}
                    message={message}
                    onBookmarkPressed={() => { }}
                    isNewest={true}
                    accessory={<><br /><InterviewTypeSelection /></>}
                />
            </>);
    }
}

const interviewMessageCell = (message: Message, interview: Interview): ReactNode => {
    let newMessage = { ...message };
    let accessory: ReactNode | undefined;

    const preamble = interview.preamble ?? '';

    if (interview.totalQuestions) {
        newMessage = {
            ...newMessage,
            content: initialInterviewPrompt(interview)
        };
        accessory = undefined;
    } else if (interview.type === InterviewType.job && !interview.jobTitle) {
        // No job selected yet
        newMessage = {
            ...newMessage,
            content: `Which saved job would you like to do an interview for?`
        }
        accessory = <><br /><SavedJobSelection /></>;
    } else if (interview.type === InterviewType.specified && interview.state === InterviewState.pending && !interview.userSpecifiedRole) {
        newMessage = {
            ...newMessage,
            content: `${preamble}What type of job / role would you like to do an interview for?`,
        }
    } else {
        newMessage = {
            ...newMessage,
            content: initialInterviewPrompt(interview),
        };
        accessory = <><br /><NumQuestionsPills /></>;
    }

    return (
        <MessageCell
            key={0}
            message={newMessage}
            onBookmarkPressed={() => { }}
            isNewest={true}
            accessory={accessory}
        />
    );
}

export const interviewConcludedMessageCell = (message: Message, interview: Interview): ReactNode => {
    return (<>
        <MessageCell
            key={0}
            message={message}
            onBookmarkPressed={() => { }}
            isNewest={true}
            accessory={<><br /><SaveInterviewAccessory /></>}
        />
    </>);
}

export const formattedInterviewText = (interview: Interview): string => {
    if (!interview.finalSummary) return 'Interview is incomplete';
    let markdown = '';
    (interview.questions ?? []).map((question, index) => {
        markdown += `## Question ${index + 1}\n` +
            `${question}\n\n
&nbsp;\n
` +
            `**Your Answer**\n\n` +
            `${interview.answers ? interview.answers[index] : 'No answer provided'}\n\n
&nbsp;\n
` +
            `${interview.feedback ? interview.feedback[index] : 'No feedback provided'}\n\n
&nbsp;\n
`;
    });

    return markdown;
}
