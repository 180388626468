import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import preambleService from '../../services/preambleService';
import suggestedPromptsService from '../../services/suggestedPromptsService';
import LLMModel from '../../shared/models/LLMModels';

interface PromptState {
    interviewPreamble: string;
    interviewPrompt: string;
    interviewModel: string;

    resumePrompt: string;
    resumeNameModel: string;
    resumeNameToggle: boolean;

    jobMatchPrompt: string;
}

const initialState: PromptState = {
    interviewModel: localStorage.getItem('interviewModel') ?? LLMModel.gpt4o,
    interviewPreamble: localStorage.getItem('interviewPreamble') ?? preambleService.getInterviewPreamble(),
    interviewPrompt: localStorage.getItem('interviewPrompt') ?? preambleService.getInterviewMessage(),

    resumePrompt: localStorage.getItem('resumePrompt') ?? suggestedPromptsService.getJobPrompts()[1].prompt,
    resumeNameModel: localStorage.getItem('resumeNamingModel') ?? LLMModel.gpt4omini,
    resumeNameToggle: false,

    jobMatchPrompt: localStorage.getItem('jobMatchPrompt') ?? suggestedPromptsService.getSuggestedPrompts()[0].prompt,
};

export const promptsSlice = createSlice({
    name: 'prompts',
    initialState,
    reducers: {
        setInterviewModel: (state, action: PayloadAction<string>) => {
            state.interviewModel = action.payload;
            localStorage.setItem('interviewModel', action.payload);
        },
        setInterviewPreamble: (state, action: PayloadAction<string>) => {
            state.interviewPreamble = action.payload;
            localStorage.setItem('interviewPreamble', action.payload);
        },
        setInterviewPrompt: (state, action: PayloadAction<string>) => {
            state.interviewPrompt = action.payload;
            localStorage.setItem('interviewPrompt', action.payload);
        },
        resetInterviewPreamble: (state) => {
            state.interviewPreamble = preambleService.getInterviewPreamble();
            localStorage.setItem('interviewPreamble', preambleService.getInterviewPreamble());
        },
        resetInterviewPrompt: (state) => {
            state.interviewPrompt = preambleService.getInterviewMessage();
            localStorage.setItem('interviewPrompt', preambleService.getInterviewMessage());
        },
        setResumePrompt: (state, action: PayloadAction<string>) => {
            state.resumePrompt = action.payload;
            localStorage.setItem('resumePrompt', action.payload);
        },
        resetResumePrompt: (state) => {
            state.resumePrompt = suggestedPromptsService.getJobPrompts()[1].prompt;
            localStorage.setItem('resumePrompt', suggestedPromptsService.getJobPrompts()[1].prompt);
        },
        setResumeModel: (state, action: PayloadAction<string>) => {
            state.resumeNameModel = action.payload;
            localStorage.setItem('resumeNamingModel', action.payload);
        },
        toggleResumeName: (state) => {
            state.resumeNameToggle = !state.resumeNameToggle;
        },
        setJobMatchPrompt: (state, action: PayloadAction<string>) => {
            state.jobMatchPrompt = action.payload;
            localStorage.setItem('jobMatchPrompt', action.payload);
        },
        resetJobMatchPrompt: (state) => {
            state.jobMatchPrompt = suggestedPromptsService.getSuggestedPrompts()[0].prompt;
            localStorage.setItem('jobMatchPrompt', suggestedPromptsService.getSuggestedPrompts()[0].prompt);
        },
    },
})

export const {
    setInterviewModel,
    setInterviewPreamble,
    setInterviewPrompt,
    resetInterviewPreamble,
    resetInterviewPrompt,
    setResumePrompt,
    resetResumePrompt,
    setJobMatchPrompt,
    resetJobMatchPrompt,
    setResumeModel,
    toggleResumeName,
} = promptsSlice.actions

export default promptsSlice.reducer
