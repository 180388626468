import { Box } from "@mui/material";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import ChatHistoryIcon from "../../assets/icons/chat_history_icon";
import InterviewIcon from "../../assets/icons/interview_icon";
import ResumeIconSmall from "../../assets/icons/resume_icon_small";
import SavedDocsIcon from "../../assets/icons/saved_docs_icon";
import SavedJobsIcon from "../../assets/icons/saved_jobs_icon";
import MainChatPage from "../../chat/pages/MainChatPage";
import { Strings } from "../../i18n";
import ChatHistorySidebarView from "../../mainTabs/chatHistory/ChatHistorySidebarView";
import InterviewSidebarView from "../../mainTabs/interviews/InterviewSidebarView";
import SavedJobsSidebarView from "../../mainTabs/savedJobs/SavedJobsSidebarView";
import SavedResourcesSidebarView from "../../mainTabs/savedResources/SavedResourcesSidebarView";
import SavedResumesSidebarView from "../../mainTabs/savedResumes/SavedResumeSidebarView";

export enum MainTabType {
    chatHistory = 0,
    savedJobs = 1,
    savedResources = 2,
    savedResumes = 3,
    interviews = 4,
    // faqs = 3,
    // settings = 4,
}

export const profileTabIndex = 100;

export class MainTab {
    type: MainTabType;

    constructor(type: MainTabType) {
        this.type = type;
    }

    localizedName = (): string => {
        const { t } = useTranslation();
        switch (this.type) {
            case MainTabType.chatHistory:
                return t('chatHistory');
            case MainTabType.savedJobs:
                return t('savedJobs');
            case MainTabType.savedResources:
                return t('savedResources');
            case MainTabType.savedResumes:
                return t(Strings.savedResumes);
            case MainTabType.interviews:
                return t(Strings.interviews);
            // case MainTabType.savedDocs:
            //     return t('savedDocs');
            // case MainTabType.faqs:
            //     return t('faqs');
            // case MainTabType.settings:
            //     return t('settings');

        }
    }

    icon = (color: string): ReactNode => {
        switch (this.type) {
            case MainTabType.chatHistory:
                return <ChatHistoryIcon color={color} />
            case MainTabType.savedJobs:
                return <SavedJobsIcon color={color} />
            case MainTabType.savedResources:
                return <SavedDocsIcon color={color} />
            case MainTabType.savedResumes:
                return <ResumeIconSmall color={color} />
            case MainTabType.interviews:
                return <InterviewIcon color={color} />
            // case MainTabType.faqs:
            //     return <FAQIcon color={color} />
            // case MainTabType.settings:
            //     return <SettingsIcon color={color} />

        }
    }

    get view(): ReactNode {
        switch (this.type) {
            case MainTabType.chatHistory:
                return <MainChatPage />;
            case MainTabType.savedJobs:
                return <MainChatPage />;
            case MainTabType.savedResources:
                return <MainChatPage />;
            case MainTabType.savedResumes:
                return <MainChatPage />;
            case MainTabType.interviews:
                return <MainChatPage />;
            // case MainTabType.faqs:
            //     return <FAQsView />;
            // case MainTabType.settings:
            //     return <SettingsView />;
        }
        return <Box></Box>;
    }

    get sidebar(): ReactNode | undefined {
        switch (this.type) {
            case MainTabType.chatHistory:
                return <ChatHistorySidebarView />;
            case MainTabType.savedJobs:
                return <SavedJobsSidebarView />;
            case MainTabType.savedResources:
                return <SavedResourcesSidebarView />;
            case MainTabType.savedResumes:
                return <SavedResumesSidebarView />;
            case MainTabType.interviews:
                return <InterviewSidebarView />;
            default:
                return undefined;
        }
    }

}
