import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Bars } from 'react-loader-spinner';
import ReactMarkdown from 'react-markdown';
import CloseIcon from '../../assets/icons/close_icon';
import SavedIcon from '../../assets/icons/save_icon_selected';
import SaveIcon from '../../assets/icons/save_icon_unselected';
import { generatePromptInfo } from '../../profile/models/UserInfo';
import { sendMessageAction } from '../../redux/actions/ChatActions';
import { ClearAllFocusedAction } from '../../redux/actions/GlobalActions';
import { StartInterviewForJobAction } from '../../redux/actions/InterviewActions';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setCurrentChatAction, toggleFollowChat } from '../../redux/reducers/appReducer';
import { addSavedJob, expireJob, removeSavedJob } from '../../redux/reducers/savedJobsReducer';
import JobsService from '../../services/jobsService';
import suggestedPromptsService, { SuggestedPrompt } from '../../services/suggestedPromptsService';
import ClickableOpacityDiv from '../../shared/components/ClickableOpacityDiv';
import NotificationBar, { NotificationType } from '../../shared/components/NotificationBar';
import OutlinedButton from '../../shared/components/OutlinedButton';
import PrimaryButton from '../../shared/components/PrimaryButton';
import SuggestionRow from '../../shared/components/SuggestionRow';
import Job from '../../shared/models/Job';
import zIndicies from '../../shared/utils/zIndexConstants';
import { AppColors, sharpTransition } from '../../theme/AppTheme';
import JobInfoRow from '../components/JobInfoRow';
import './JobDetails.css';



const DetailsPage = styled('div')((props: { position: number, fixed: boolean, isMobile: boolean }) => ({
    position: props.fixed ? 'absolute' : 'relative',
    right: props.fixed ? -props.position : undefined,
    top: props.fixed ? 0 : undefined,
    bottom: props.fixed ? 0 : undefined,
    backgroundColor: AppColors.white,
    transition: `right 0.2s ${sharpTransition}`,
    width: props.isMobile ? props.fixed ? '95%' : '100%' : (props.fixed ? 'clamp(500px, 58vw, 1200px)' : '100%'),
    overflowY: 'hidden',
    height: '100%',
    maxHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
}));

const ScrollableContainer = styled('div')((props: { isMobile: boolean }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingLeft: props.isMobile ? '40px' : 'clamp(24px, 6vw, 145px)',
    paddingRight: props.isMobile ? '40px' : 'clamp(24px, 6vw, 145px)',
    paddingTop: props.isMobile ? '30px' : '54px',
    alignItems: 'center',
    width: props.isMobile ? 'calc(100% - 80px)' : '100%',
    height: props.isMobile ? 'calc(100% - 50px)' : '100%',
    maxHeight: '100%',
    overflowY: 'auto',
    paddingBottom: '50px',
    zIndex: zIndicies.jobFlyout,
}));

const StyledButtons = styled('div')((props: { isMobile: boolean }) => ({
    padding: '10px',
    borderTop: `1px solid ${AppColors.grey.border}`,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '15px',
    width: props.isMobile ? 'calc(100% - 20px)' : '100%',
    backgroundColor: AppColors.white,
    marginBottom: 'env(safe-area-inset-bottom)',
}));

interface JobDetailsContentPageProps {
    job: Job;
    animated: boolean;
    onClose?: () => void;
}

const JobDetailsContentPage = (props: JobDetailsContentPageProps) => {
    const { job, onClose, animated } = props;
    const savedJobs = useAppSelector(store => store.savedJobsState.jobsArray ?? []);
    const isSaved = savedJobs.some(j => (j.id === job.jobId) || (j.url === job.url));
    const isMobile = useAppSelector(store => store.appState.isMobile);
    const jobModel = useAppSelector(store => store.appState.jobSummaryModel);
    const jobSummaryPreamble = useAppSelector(store => store.appState.jobSummaryPreamble);
    const jobSummaryMessage = useAppSelector(store => store.appState.jobSummaryMessage);
    const currentUser = useAppSelector((s) => s.userState.currentUser);
    const [position, setPosition] = useState(animated ? 800 : 0);
    const [bookmarked, setBookmarked] = useState(isSaved);
    const [loadingUrl, setLoadingUrl] = useState(false);
    const [expired, setExpired] = useState(job.expired ?? false);

    const [fetchingSummary, setFetchingSummary] = useState(false);
    const [jobSummary, setJobSummary] = useState('');

    const dispatch = useAppDispatch();

    const fetchSummary = async (signal: AbortSignal) => {
        try {
            const summary = await JobsService.fetchSummary(job, jobModel, jobSummaryMessage, jobSummaryPreamble, signal);
            if (summary) {
                setJobSummary(summary);
            }
        } catch (error) {
            console.error('Error fetching job summary:', error)
        } finally {
            setFetchingSummary(false);
        }
    }

    useEffect(() => {
        let abortController = new AbortController();
        setPosition(0);
        if (!expired && !fetchingSummary && jobSummary === '') {
            setFetchingSummary(true);
            fetchSummary(abortController.signal);
        } else {
            setFetchingSummary(false);
        }

        return () => {
            abortController.abort();
        }
    }, []);

    useEffect(() => {
        let abortController = new AbortController();
        setExpired(job.expired ?? false);
        setJobSummary('');
        setFetchingSummary(true);
        fetchSummary(abortController.signal);
        return () => {
            abortController.abort();
        }
    }, [job]);

    const close = () => {
        if (onClose)
            onClose();
        if (animated) {
            setPosition(800);
            setTimeout(() => {
                dispatch(ClearAllFocusedAction());
            }, 200);
        } else {
            dispatch(ClearAllFocusedAction());
        }
    }

    const onApply = async () => {
        setLoadingUrl(true);
        const jobUrl = await JobsService.fetchJobApplyUrl(job);
        if (jobUrl) {
            window.open(jobUrl, '_blank');
        } else {
            await JobsService.expireJob(job);
            dispatch(expireJob(job));
            setExpired(true);
        }
        setLoadingUrl(false);
    }

    const onBookmark = async () => {
        if (!bookmarked) {
            setBookmarked(true);
            const savedJob = await JobsService.saveJob(job);
            dispatch(addSavedJob(job));
            if (!savedJob) {
                setBookmarked(false);
            }
        } else {
            await JobsService.unsaveJob(job)
            dispatch(removeSavedJob(job));
            setBookmarked(false);
        }
    }

    const onSuggestionPressed = (suggestion: SuggestedPrompt) => {
        dispatch(setCurrentChatAction(undefined));
        if (suggestion.title === 'interview') {
            dispatch(StartInterviewForJobAction(job));
            dispatch(ClearAllFocusedAction());
        } else {
            const injectedInfoPrompt = injectJobInfoIntoPrompt(suggestion.prompt);
            const injectedPrompt = injectResumeIntoPrompt(injectedInfoPrompt);
            const fullPrompt = `${injectedPrompt}\nJob Description: ${job.summary}\nTitle: ${job.title}\nCompany: ${job.company}\nSkills: ${job.skills}`;
            const isNewChat = suggestion.title === 'interview' || !animated;

            dispatch(sendMessageAction(fullPrompt, isNewChat));
            dispatch(ClearAllFocusedAction());
            dispatch(toggleFollowChat());
        }
    }

    const injectResumeIntoPrompt = (prompt: string): string => {
        if (prompt.includes("[RESUME_TEXT]") === false) return prompt;
        const resumeText = currentUser?.resume?.textResume;
        if (resumeText) {
            const newPrompt = prompt.replace("[RESUME_TEXT]", resumeText);
            return newPrompt;
        } else {
            const promptNumberRegex = /\[JOB\d+\]/;
            const promptNumberMatch = prompt.match(promptNumberRegex);
            if (promptNumberMatch) {
                const promptNumber = promptNumberMatch[0];
                return `${promptNumber}This user hasn't uplaoded a resume yet. Please tell them "I see you haven't uploaded a resume yet. Please upload your resume in your profile settings so I can help you with this.";`
            }
            return prompt;
        }
    }

    const injectJobInfoIntoPrompt = (prompt: string): string => {
        if (prompt.includes("[DESIRED_INFO]") === false) return prompt;
        const userInfoText = generatePromptInfo(currentUser?.info);
        const newPrompt = prompt.replace("[DESIRED_INFO]", userInfoText);
        return newPrompt;
    }

    return (
        <DetailsPage position={position} fixed={animated} isMobile={isMobile}>
            {animated && <>
                <ClickableOpacityDiv style={{
                    position: 'absolute',
                    left: '32px',
                    top: '29px',
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: AppColors.white,
                    zIndex: zIndicies.jobCloseButton,
                }}
                    onClick={close}>
                    <CloseIcon />
                    <span style={{ marginLeft: '5px' }}>Close</span>
                </ClickableOpacityDiv>

            </>}
            {expired && <NotificationBar type={NotificationType.expiredJob}
                unAnimated
                marginTop={48}
                onClose={() => { }}
                shouldClose={false}
                message={'This job is no longer available'}
                onAction={isSaved ? onBookmark : undefined}
            />}
            {!expired && (!isMobile || (isMobile && animated)) && (!!(job.jobId || job.url)) && !(expired && !bookmarked) ?
                <ClickableOpacityDiv
                    onClick={onBookmark}
                    style={{
                        position: 'absolute',
                        right: '34px',
                        top: '34px',
                        display: 'flex',
                        alignItems: 'center',
                        zIndex: zIndicies.jobBookmarkButton,
                    }}
                >
                    {bookmarked ? <SavedIcon /> : <SaveIcon />}
                </ClickableOpacityDiv> : <></>}
            <ScrollableContainer isMobile={isMobile}>
                {/* Header */}
                <div>
                    <Typography variant='h1' style={{ color: AppColors.black, fontSize: '24px', marginTop: '50px', marginBottom: '12px' }}>
                        {job.company}
                    </Typography>
                    <Typography variant='h5' style={{ color: AppColors.grey.darkish, marginBottom: '40px', }}>
                        {job.title}
                    </Typography>
                </div>
                <JobInfoRow title={job.location1} subtitle={job.location2 ?? '-'} />
                <JobInfoRow title={`${job.fit}%`} subtitle={'Match'} hideTopBorder />
                <JobInfoRow title={'Skills'} subtitle={job.skills} hideTopBorder />
                <JobInfoRow
                    title={'Summary'}
                    subtitle={
                        jobSummary
                            ? <ReactMarkdown className={'wrap-text'}>
                                {jobSummary?.replace(/\n/g, '   \n')}
                            </ReactMarkdown> ?? 'Click "Apply" to learn more.'
                            : <Bars
                                height="30"
                                width="30"
                                color={AppColors.pink.dark}
                                ariaLabel="bars-loading"
                                visible={true}
                            />
                    }
                    hideTopBorder
                    hideBottomBorder
                    bigMargin
                    expandable
                />
                <JobInfoRow
                    title={'Suggested actions'}
                    subtitle={suggestedPromptsService.getJobPrompts().map((prompt, index) => ((!expired || (expired && prompt.title === 'expired')) ? <SuggestionRow key={0} title={prompt.description} onClick={() => onSuggestionPressed(prompt)} /> : null))}
                    hideBottomBorder
                    bigMargin
                />
            </ScrollableContainer>
            {!(expired && !bookmarked) && <StyledButtons isMobile={isMobile}>
                {loadingUrl && <Bars
                    height="30"
                    width="25"
                    color={AppColors.pink.dark}
                    ariaLabel="bars-loading"
                    visible={true}
                />}
                {!expired && !loadingUrl && <PrimaryButton title={'Apply'} disabled={false} onClick={onApply} height={'48px'} style={{ width: '118px' }} />}
                <OutlinedButton title={bookmarked ? (expired ? 'Remove Job' : 'Unsave') : 'Save'} disabled={false} onClick={onBookmark} height={'48px'} style={{ width: '118px' }} />
            </StyledButtons>
            }
        </DetailsPage>
    )
}

export default JobDetailsContentPage