import { auth } from "../firebaseConfigs";
import Job from "../shared/models/Job";

class JobsService {
    static saveJob = async (job: Job): Promise<Job | undefined> => {
        try {
            const token = await auth.currentUser?.getIdToken();

            const res = await fetch(`${process.env.REACT_APP_API_URL}/jobs/save`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ ...job, job_id: job.id }),
            });

            const data: Job = await res.json();
            return data;
        } catch (error) {
            console.error('Error saving job:', error);
        }
    };

    static unsaveJob = async (job: Job) => {
        try {
            const token = await auth.currentUser?.getIdToken();

            const res = await fetch(`${process.env.REACT_APP_API_URL}/jobs/remove`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(job),
            });

            if (res.status !== 200) {
                console.error(`Failed to unsave job: ${res.status}`);
            }
        } catch (error) {
            console.error('Error unsaving job:', error);
        }
    };

    static fetchSummary = async (job: Job, model: string, message: string, preamble: string, signal: AbortSignal): Promise<string | undefined> => {
        try {
            const token = await auth.currentUser?.getIdToken();
            const res = await fetch(`${process.env.REACT_APP_API_URL}/jobs/summary`, {
                method: 'POST',
                signal: signal,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ ...job, model, preamble, message }),
            });

            if (res.status !== 200) {
                console.error(`Failed to fetch job summary: ${res.status}`);
            }
            const data: string = await res.text();
            console.log('Job summary:', data);
            return data;
        } catch (error) {
            console.error('Error fetching job summary:', error);
        }
    };

    static fetchSavedJobs = async (): Promise<Job[]> => {
        try {
            const token = await auth.currentUser?.getIdToken();
            const res = await fetch(`${process.env.REACT_APP_API_URL}/jobs/`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (res.status !== 200) {
                console.error(`Failed to get saved jobs: ${res.status}`);
            }
            const json: Job[] = await res.json();
            return json;
        } catch (error) {
            console.error('Error getting saved jobs:', error);
        }
        return [];
    }

    static fetchJobApplyUrl = async (job: Job): Promise<string | undefined> => {
        try {
            const token = await auth.currentUser?.getIdToken();
            const res = await fetch(`${process.env.REACT_APP_API_URL}/jobs/${job.jobId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (res.status !== 200) {
                return undefined;
            }
            const data: string = await res.text();
            return data;
        } catch (error) {
            console.error('Error getting apply url:', error);
        }
    }

    static expireJob = async (job: Job): Promise<void> => {
        try {
            const token = await auth.currentUser?.getIdToken();
            const res = await fetch(`${process.env.REACT_APP_API_URL}/jobs/${job.jobId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (res.status !== 200) {
                console.error(`Failed to expire job: ${res.status}`);
            }
        } catch (error) {
            console.error('Error expiring job:', error);
        }
    }
}

export default JobsService;
